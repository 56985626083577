@
<template>
    <div v-if="ready">
        <v-container fluid>
            <v-row>
                <v-col cols="12" class="d-flex align-center justify-space-between">
                    <h1 class="text-h4" style="flex-grow: 1">
                        {{ heading }}
                        <span v-if="ready && !loading && rapporter.length">&nbsp;( {{ rapporter.length }})</span>
                    </h1>

                    <v-btn color="primary" v-on:click="create" v-if="canCreate">
                        <v-icon left>mdi-plus</v-icon>
                        Generer
                    </v-btn>
                </v-col>
            </v-row>
            <v-row>
                <v-toolbar class="mt-6" color="grey lighten-4" elevation="4">
                    <v-menu offset-y>
                        <template v-slot:activator="{ on, attrs }">
                            <v-btn icon color="primary" v-bind="attrs" v-on="on">
                                <v-icon>mdi-dots-vertical</v-icon>
                            </v-btn>
                        </template>
                        <v-list dense>
                            <v-subheader>Med alle</v-subheader>
                            <v-list-item-group color="primary">
                                <v-list-item v-on:click="exportExcel">
                                    <v-list-item-icon>
                                        <v-icon>mdi-file-excel</v-icon>
                                    </v-list-item-icon>
                                    <v-list-item-content>
                                        <v-list-item-title>Eksporter</v-list-item-title>
                                    </v-list-item-content>
                                </v-list-item>
                            </v-list-item-group>
                        </v-list>
                    </v-menu>
                    <v-col cols="2">
                        <v-select v-model="ar" :items="arValg" item-text="navn" item-value="id" label="År" hide-details></v-select>
                    </v-col>
                    <v-col cols="2">
                        <v-select v-model="maned" :items="manedValg" item-text="navn" item-value="id" label="Måned" hide-details></v-select>
                    </v-col>
                    <v-col cols="2">
                        <v-select v-model="levert" :items="levertValg" item-text="navn" item-value="id" label="Levert" hide-details></v-select>
                    </v-col>
                </v-toolbar>
            </v-row>
            <v-row>
                <v-col cols="12">
                    <v-data-table
                        :headers="headers"
                        :items="rapporter"
                        :items-per-page="-1"
                        v-on:click:row="edit"
                        item-class="v-data-table-item--link"
                        :custom-sort="customSort"
                        sort-by="gruppe"
                        must-sort
                        hide-default-footer
                        no-data-text="Ingen rapporter"
                    >
                        <template v-slot:item.gruppe="{ item }"> {{ item.gruppe.lopenr }} </template>
                        <template v-slot:item.ar="{ item }"> {{ item.dato | format('YYYY') }} </template>
                        <template v-slot:item.maned="{ item }"> {{ item.dato | format('MMMM') }} </template>
                        <template v-slot:item.frivillig="{ item }">
                            {{ item.frivillig ? (item.frivillig.arkivert ? '(Slettet frivillig)' : item.frivillig.fulltNavn) : '' }}
                        </template>
                        <template v-slot:item.antallMoter="{ item }"> {{ item.antallMoter || '' }} </template>
                        <template v-slot:item.antallDeltakerePrMote="{ item }"> {{ item.antallDeltakerePrMote || '' }} </template>
                        <template v-slot:item.antallAktiveDeltakere="{ item }"> {{ item.antallAktiveDeltakere || '' }} </template>
                        <template v-slot:item.antallNyeDeltakere="{ item }"> {{ item.antallNyeDeltakere || '' }} </template>
                        <template v-slot:item.antallDeltakereSluttet="{ item }"> {{ item.antallDeltakereSluttet || '' }} </template>
                        <template v-slot:item.antallDeltakereIPermisjon="{ item }"> {{ item.antallDeltakereIPermisjon || '' }} </template>
                        <template v-slot:item.antallLedigePlasser="{ item }"> {{ item.antallLedigePlasser || '' }} </template>
                        <template v-slot:item.antallTimer="{ item }"> {{ item.antallTimer || '' }} </template>
                        <template v-slot:item.kommentar="{ item }">
                            <template v-if="item.kommentar">
                                <v-icon small>mdi-information</v-icon>
                            </template>
                        </template>
                        <template v-slot:item.opprettet="{ item }"> {{ item.opprettet | format('DD.MM.YYYY') }} </template>
                        <template v-slot:item.levert="{ item }"
                            ><template v-if="item.levert"> {{ item.levert | format('DD.MM.YYYY') }}</template></template
                        >
                    </v-data-table>
                </v-col>
            </v-row>
        </v-container>
        <report-dialog ref="reportDialog" :rapporter="sortertListe" v-on:closed="closedDialog"></report-dialog>
    </div>
</template>

<script>
import moment from 'moment';
import { mapGetters, mapActions } from 'vuex';
import * as XLSX from 'xlsx/xlsx.mjs';
import ReportDialog from '@/pages/grief/ReportDialog.vue';

export default {
    name: 'VisitList',
    components: {
        ReportDialog,
    },
    data() {
        return {
            ready: false,
            loading: false,

            headers: [
                { text: 'Gruppe', value: 'gruppe' },
                { text: 'År', value: 'ar' },
                { text: 'Måned', value: 'maned' },
                { text: 'Antall møter', value: 'antallMoter' },
                { text: 'Antall deltakere', value: 'antallDeltakerePrMote' },
                { text: 'Antall aktive deltakere', value: 'antallAktiveDeltakere' },
                { text: 'Antall nye deltakere', value: 'antallNyeDeltakere' },
                { text: 'Antall deltakere sluttet', value: 'antallDeltakereSluttet' },
                { text: 'Antall deltakere i permisjon', value: 'antallDeltakereIPermisjon' },
                { text: 'Antall ledige plasser', value: 'antallLedigePlasser' },
                { text: 'Kommentar', value: 'kommentar', align: 'center' },
                { text: 'Frivillig', value: 'frivillig' },
                { text: 'Levert', value: 'levert' },
            ],
            alleRapporter: [],
            sortertListe: [],

            ar: null,
            arValg: [],
            maned: null,
            manedValg: [],

            levert: '',
            levertValg: [
                { id: '', navn: '(alle)' },
                { id: 'ja', navn: 'Ja' },
                { id: 'nei', navn: 'Nei' },
            ],
        };
    },
    filters: {
        format(value, format) {
            if (value) {
                return value.format(format);
            }
            return '';
        },
    },
    watch: {
        ar: function (value) {
            this.ar = value;
            if (this.ready) {
                this.update();
            }
        },
        maned: function (value) {
            this.maned = value;
            if (this.ready) {
                this.update();
            }
        },
        levert: function (value) {
            this.levert = value;
            if (this.ready) {
                this.filter();
            }
        },
    },
    computed: {
        ...mapGetters('api', ['user', 'hasRole']),
        heading: function () {
            let heading = 'Månedsrapporter';
            return heading;
        },
        canCreate: function () {
            return this.user.id == '368a1b32-43cb-47c0-8013-8512f487239a'; /// todo
        },
    },

    /**
     * created
     */
    created: async function () {
        const now = moment();
        const currentMonth = now.format('M');
        const currentYear = now.format('Y');

        const years = [{ id: null, navn: '(alle) ' }];
        for (let year = 2023; year <= currentYear; year++) {
            year = year.toString();
            years.push({
                id: year,
                navn: year,
            });
        }

        const months = [{ id: null, navn: '(alle) ' }];
        for (let month = 1; month <= 12; month++) {
            months.push({
                id: month,
                navn: moment(currentYear + '-' + month + '-01', 'YYYY-M-DD').format('MMMM'),
            });
        }

        this.manedValg = months;
        this.arValg = years;

        this.ar = currentYear;
        this.maned = currentMonth - 1;

        await this.update();
    },
    methods: {
        ...mapActions('api', ['request', 'formatGriefGroupReport']),

        /**
         * create
         */
        create: async function () {
            const response = await this.request({
                method: 'get',
                url: '/sorggruppe',
                params: {
                    bareAktive: true,
                },
            });

            if (response && Array.isArray(response)) {
                let created = false;
                const lastMonth = moment().subtract(1, 'month').endOf('month');

                for (const gruppe of response) {
                    let harLeder = false;
                    if (gruppe.ledere) {
                        for (const leder of gruppe.ledere) {
                            if (!leder.fjernet && leder.person) {
                                harLeder = true;
                                break;
                            }
                        }
                    }

                    if (harLeder) {
                        let rapportId = null;
                        for (const rapport of this.alleRapporter) {
                            if (gruppe.id == rapport.gruppe.id) {
                                if (lastMonth.isSame(rapport.dato, 'day')) {
                                    rapportId = rapport.id;
                                    break;
                                }
                            }
                        }
                        if (!rapportId) {
                            await this.request({
                                method: 'post',
                                url: '/sorggruppe/rapportskjema/',
                                data: {
                                    gruppeId: gruppe.id,
                                    dato: lastMonth.format('DD.MM.YYYY'),
                                },
                            });
                            created = true;
                        }
                    }
                }

                if (created) {
                    await this.update();
                }
            }
        },

        /**
         * update
         */
        update: async function () {
            try {
                this.loading = true;

                const response = await this.request({
                    method: 'get',
                    url: this.hasRole('ANSATT') ? '/sorggruppe/rapportskjema' : '/sorggruppe/rapportskjema/frivillig/' + this.user.id + '?ikkeLeverte=false',
                });

                if (response && Array.isArray(response)) {
                    this.alleRapporter = [];
                    for (const rapport of response) {
                        this.alleRapporter.push(await this.formatGriefGroupReport(rapport));
                    }
                }

                this.filter();
                this.loading = false;
                this.ready = true;
            } catch (e) {
                console.log(e);
            }
        },

        /**
         * filter
         */
        filter: function () {
            const rapporter = [];
            let compare = null;
            let dateFilter = null;
            if (this.ar && this.maned) {
                dateFilter = moment(this.ar + '-' + this.maned + '-01', 'YYYY-M-DD');
                compare = 'month';
            } else if (this.ar) {
                dateFilter = moment(this.ar + '-01-01', 'YYYY-M-DD');
                compare = 'year';
            }
            for (const rapport of this.alleRapporter) {
                if (
                    (!this.levert || (this.levert == 'ja' && rapport.levert) || (this.levert == 'nei' && !rapport.levert)) &&
                    (!dateFilter || (dateFilter && rapport.dato.isSame(dateFilter, compare)))
                ) {
                    rapporter.push(rapport);
                }
            }
            this.rapporter = rapporter;
            this.sortertListe = rapporter;
        },

        /**
         * customSort
         */
        customSort: function (items, index, isDesc) {
            if (index.length) {
                items.sort((a, b) => {
                    const field = index[0];
                    switch (field) {
                        case 'gruppe':
                            if (!isDesc[0]) {
                                return a.gruppe.lopenr - b.gruppe.lopenr;
                            } else {
                                return b.gruppe.lopenr - a.gruppe.lopenr;
                            }

                        case 'dato':
                            if (!isDesc[0]) {
                                return a.dato.diff(b.dato);
                            } else {
                                return b.dato.diff(a.dato);
                            }

                        case 'levert':
                            if (a.levert && b.levert) {
                                if (!isDesc[0]) {
                                    return a.levert.diff(b.levert);
                                } else {
                                    return b.levert.diff(a.levert);
                                }
                            }
                            a = a.levert ? a.levert.unix() : 0;
                            b = b.levert ? b.levert.unix() : 0;
                            return !isDesc[0] ? a - b : b - a;

                        case 'antallMoter':
                        case 'antallDeltakerePrMote':
                        case 'antallAktiveDeltakere':
                        case 'antallNyeDeltakere':
                        case 'antallDeltakereSluttet':
                        case 'antallDeltakereIPermisjon':
                        case 'antallLedigePlasser':
                        case 'antallTimer':
                            a = typeof a[field] != 'undefined' ? (a[field] ? a[field] : 0) : 0;
                            b = typeof b[field] != 'undefined' ? (b[field] ? b[field] : 0) : 0;
                            return !isDesc[0] ? a - b : b - a;

                        case 'frivillig':
                            a = a.frivillig ? a.frivillig.fulltNavn : '';
                            b = b.frivillig ? b.frivillig.fulltNavn : '';
                            break;

                        default:
                            a = typeof a[field] != 'undefined' ? (a[field] ? a[field].toString() : '') : a.id;
                            b = typeof b[field] != 'undefined' ? (b[field] ? b[field].toString() : '') : b.id;
                    }
                    if (!isDesc[0]) {
                        return a.localeCompare(b, 'no', { numeric: true, sensitivity: 'base' });
                    } else {
                        return b.localeCompare(a, 'no', { numeric: true, sensitivity: 'base' });
                    }
                });
            }
            this.sortertListe = items;
            return items;
        },

        /**
         * exportExcel
         */
        exportExcel: function () {
            const filename = 'Månedsrapporter';

            const table = [];

            const header = [];
            //const wscols = [];

            for (const field of this.headers) {
                header.push(field.text);
                //xxx
                //wscols.push({ width: fields[field].width || 20 });
            }

            table.push(header);

            for (const rapport of this.sortertListe) {
                const row = [];
                for (const field of this.headers) {
                    let value = '';
                    switch (field.value) {
                        case 'gruppe':
                            value = rapport.gruppe.lopenr;
                            break;

                        case 'frivillig':
                            value = rapport.frivillig ? rapport.frivillig.fulltNavn : '';
                            break;

                        case 'dato':
                            value = rapport.dato.format('DD.MM.YYYY');
                            break;

                        case 'levert':
                            value = rapport.levert ? rapport.levert.format('DD.MM.YYYY') : '';
                            break;

                        default:
                            value = typeof rapport[field.value] != 'undefined' ? rapport[field.value] : '';
                    }
                    row.push(value);
                }
                table.push(row);
            }

            const ws = XLSX.utils.aoa_to_sheet(table, { dateNF: 'YYYYMMDD HH:mm:ss' });
            //ws['!cols'] = wscols;

            const wb = XLSX.utils.book_new();
            XLSX.utils.book_append_sheet(wb, ws, filename);
            XLSX.writeFile(wb, filename + '.xlsx');
        },

        /**
         * edit
         */
        edit: function (item, { index }) {
            this.$refs.reportDialog.edit(index);
        },

        /**
         * closedDialog
         */
        closedDialog: async function () {
            await this.update();
        },
    },
};
</script>
<style lang="scss">
.v-data-table-item--link {
    cursor: pointer;
}
thead tr th {
    vertical-align: top;
}
</style>
